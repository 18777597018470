import {ICreations} from "./CreationInterface";

const data: ICreations = [
  {
    title: 'Machina',
    slug: 'machina',
    type: 'Collection',
    titleImage: 'machina/menu.jpg',
    descriptions: ['Machina porte un regard sur la robotique et son language. Les circuits imprimés sont le reflet d’une pensée utile, où chaque élément est affecté à une tâche et à un emplacement précis. Révéler la beauté et la complexité de cette construction humaine à partir du regard de l’artiste-artisan, permet un dialogue entre la technologie de pointe et l’artisanat d’art. À nouveau, la broderie est un medium qui apporte un discours poétique sur les créations et le savoir-faire de l’humanité.'],
    images: [
      'machina/1.jpg',
      'machina/2.jpg',
      'machina/3.jpg',
      'machina/4.jpg',
      'machina/5.jpg',
      'machina/6.jpg',
      'machina/7.jpg',
      'machina/8.jpg',
      'machina/9.jpg',
      'machina/10.jpg',
    ],
    seoTitle: 'Les disques durs se brodent !',
    seoDescription: 'Regarder les composant électroniques avec un regard de brodeuse, c’est permettre à ces matières de s’exprimer différemment.',
  },
  {
    title: 'Le baiser, hommage à klimt',
    slug: 'le-baiser-hommage-a-klimt',
    subtitle: 'Collaboration avec jean-david lemarié, photographe',
    type: 'Collaboration',
    titleImage: 'leBaiser/menu.jpg',
    descriptions: [
      'Le triptyque présenté est le fruit d’un regard croisé, du travail de l’oeil et de la main. Véritable fusion entre la photographie et la broderie, le Baiser est inspiré de l’oeuvre de Klimt. Chacun à leur art, la brodeuse et le photographe ont interprété leur vision de l’artiste, traduisant ainsi un travail mixte, original et technique.',
      'Le triptyque a été exposé du 29 juin au 30 août 2019 à l’Atelier-Musée de la Broderie d’Or (42). Les photos sont à venir !',
    ],
    images: [
      'leBaiser/1.jpg',
      'leBaiser/2.jpg',
      'leBaiser/3.jpg',
    ],
    seoTitle: 'Klimt, de la photo, de la broderie.',
    seoDescription: 'Triptyque brodé en hommage à Klimt, avec les sublimes photographies de Jean David Lemarié.',
  },
  {
    title: 'Portraits brodés',
    slug: 'portraits-brodes',
    subtitle: 'Collaboration avec jean-david lemarié, photographe',
    type: 'Collaboration',
    titleImage: 'portraitsBrodes/menu.jpg',
    descriptions: ['Née de quatre yeux et quatre mains, cette collaboration est la rencontre de deux arts. Travaillant dans un même but, la photo est pensée pour la broderie, tout comme la broderie prend en compte l’espace physique mais également créatif de ce support. Testant et créant, ces portraits sont les prémices, préparant le Triptyque hommage au Baiser de Klimt.'],
    images: [
      'portraitsBrodes/5.jpg',
      'portraitsBrodes/1.jpg',
      'portraitsBrodes/6.jpg',
      'portraitsBrodes/2.jpg',
      'portraitsBrodes/9.jpg',
      'portraitsBrodes/3.jpg',
      'portraitsBrodes/7.jpg',
      'portraitsBrodes/4.jpg',
      'portraitsBrodes/8.jpg',
    ],
    seoTitle: 'Faîtes-vous tirer et broder le portrait !',
    seoDescription: 'Le portrait brodé est un objet unique et personnalisable (et aussi une idée cadeau).',
  },
  {
    title: 'Sylve',
    slug: 'sylve',
    type: 'Collection',
    titleImage: 'sylve/menu.jpg',
    descriptions: ['Inspirée de la nature, la collection Sylve redéfinit les formes et les éléments. De la vie minuscule de la mousse aux formes élevées de la forêt, les motifs proposés résonnent de ces influences. Jouant des textures et des médiums de la broderie, les lignes sinuent, s’imbriquent d’un réseau boisé. Choisissant des matières uniques, allant de la plume du paon aux fils métalloïdes, Maison Aimard use de son savoir-faire, définissant ainsi un travail original et précis. Chaque broderie se mire d’une double lecture :  les formes au loin racontent une histoire aux mille détails, surprenant le spectateur s’approchant.'],
    images: [
      'sylve/1.jpg',
      'sylve/2.jpg',
      'sylve/3.jpg',
      'sylve/4.jpg',
      'sylve/5.jpg',
      'sylve/6.jpg',
      'sylve/7.jpg',
      'sylve/8.jpg',
      'sylve/9.jpg',
      'sylve/10.jpg',
      'sylve/11.jpg',
      'sylve/12.jpg',
    ],
    seoTitle: 'Sylve, de la nature au design textile.',
    seoDescription: 'Inspirée de la nature, la collection Sylve redéfinit les formes et les éléments.',
  },
  // {
  //     title: 'Mariage',
  //     slug: 'mariage',
  //     type: 'Collection',
  //     titleImage: 'sylve/menu.jpg',
  //     descriptions: ['xxxxxx'],
  //     images: [],
  //     seoTitle: 'xxxxx.',
  //     seoDescription: 'xxxxx.',
  // },
  // {
  //     title: 'Broderie or',
  //     slug: 'broderie-or',
  //     type: 'Collection',
  //     titleImage: 'sylve/menu.jpg',
  //     descriptions: ['xxxxxx'],
  //     images: [],
  //     seoTitle: 'xxxxx.',
  //     seoDescription: 'xxxxx.',
  // },
]

export default data;
