import {IPartnersModels} from "./PartnersScreen";

const data: IPartnersModels = [
  {
    title: 'Jean-David Lemarié • Photographe',
    description: 'Jean-David Lemarié est un photographe artiste qui s’attache aux portraits et aux projets ' +
      'transversaux. Il est également poète et auteur, posant sur le monde un regard doux et original. ' +
      'Son approche est fortement inspirée des beaux arts, de la Renaissance et du maniérisme.',
    image: 'jean-david-lemarie.png',
    imageAlt: 'Photo de Jean David Lemarie',
    website: 'https://www.jdlemarie.fr/'
  },
  // {
  //     title: 'La Cahute de Marine',
  //     description: 'description',
  //     image: 'lacahutedemarine.png',
  //     imageAlt: 'Logo de La Cahute de Marine',
  //     website: 'https://lacahutedemarine.com/'
  // },
//   {
//     title: 'L’association des grenadières du haut-forez • Broderie d’or et défenseurs du savoir-faire',
//     description: 'L’association vient en appui du Musée de la Broderie d’or par son action de promotion afin de ' +
//       'faire revivre le métier des Grenadières dans le canton de Noirétable (42). Elle défend des techniques ' +
//       'régionales ainsi que le travail des femmes qui brodaient les insignes militaires, oubliées dans l’ombre ' +
//       'de l’histoire.',
//     image: 'les-grenadieres.png',
//     imageAlt: 'Logo des grenadieres',
//     website: 'https://www.facebook.com/pages/category/Arts---Entertainment/Association-les-grenadières-du-haut-forez-724787247855189/'
//   },
//   {
//     title: 'Yvonne & Alexis • Créateurs de ponts avec la broderie d’or',
//     description: 'Yvonne & Alexis sont de véritables acteurs dans la broderie d’or. Alliant traditions et ' +
//       'modernité, ils revendiquent un savoir-faire 100% français. Ils développent des connexions entre les ' +
//       'artisans régionaux en proposant notamment des couteaux brodés. Un service de broderie d’or pour les ' +
//       'tenues officielles, respectant la pure tradition française, est proposé pour les militaires et ' +
//       'hauts-fonctionnaires.',
//     image: 'yvonne-alexis.png',
//     imageAlt: 'Logo de YVONNE & ALEXIS',
//     website: 'https://www.yvonne-alexis.fr/'
//   },
]

export default data;
