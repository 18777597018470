import React from "react";
import FontAwesome from 'react-fontawesome';

import './Footer.css'


export default function Footer(): React.ReactElement {
  return (
    <footer className={'footer'}>
      <div className={'container'}>
        <p>
          <a rel={"nofollow noopener noreferrer"} target={"_blank"}
             href={"https://www.facebook.com/Maison-Aimard-343762026233856/"}
          >
            <FontAwesome className={'fontAwesome'} name={'facebook'}/>
          </a>

          <a rel={"nofollow noopener noreferrer"} target={"_blank"}
             href={"https://instagram.com/maisonaimard/"}
          >
            <FontAwesome className={'fontAwesome'} name={'instagram'}/>
          </a>
        </p>
        <p>© Maison Aimard 2019, Tous droits réservés.</p>
        <p><a href="/legal">Mentions légales.</a></p>
      </div>
    </footer>
  )
}
