import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import './CreationsDetails.css'

import dataJson from './CreationData';
import {ICreation, ICreations} from "./CreationInterface";
import Button from "../../components/button/button";
import Seo from "../../components/seo/seo";

const data: ICreations = dataJson;

export default function CreationsDetails(): React.ReactElement {
  const params = useParams();

  useEffect(() => {
    document.title = 'Maison Aimard - Creations';
    window.scrollTo(0, 0);
    // firebaseApp.analytics().logEvent('track_screen', {
    //   page_location: window.location.href,
    //   page_title: 'Maison Aimard - Creations details',
    //
    //   // @ts-ignore
    //   creation: params.slug,
    // });
  }, [params]);

  const _renderBackButton = () => (
    <Button text={'Retour'} href={'/creations'}/>
  );

  // @ts-ignore
  const slug = params.slug;

  // get relevent data
  const creation: ICreation = data.filter(value => value.slug === slug)[0];
  const {
    title,
    subtitle,
    images,
    descriptions,
    seoTitle,
    seoDescription
  } = creation;

  return (
    <div className={"creations-details-body"}>
      <Seo title={seoTitle} description={seoDescription}
           path={`/creations/${slug}`} contentType={'website'}
           image={'/logo-transparent.png'}/>
      <div className={"row"}>
        <div className={"col-12 text-center titles"}>
          <h1>{title}</h1>
          {subtitle && <h2>{subtitle}</h2>}

        </div>
      </div>

      <div className={"separator-15"}/>

      {_renderBackButton()}

      <div className={"separator-30"}/>

      <div className={"row"}>
        <div className={"col-12"}>
          {descriptions.map(value => (<p>{value}</p>))}
        </div>
      </div>

      <div className={"row images"}>
        {images.map(value => (
          <div key={value}
               className={"col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12"}
               style={{marginBottom: 25}}>
            <a href={`${process.env.PUBLIC_URL}/creations/${value}`}
               data-lightbox={`image du projet ${title}`}>
              <img className={"img-fluid"}
                   alt={`${value} du projet ${title}`}
                   src={`${process.env.PUBLIC_URL}/creations/${value}`}
              />
            </a>
          </div>
        ))}
      </div>

      {_renderBackButton()}

    </div>
  )
}
