import React, {useEffect} from 'react';
import './Creations.css'

import data from './CreationData';
import CreationBlock from "../../components/creationBlock/CreationBlock";
import {ICreation, ICreations} from "./CreationInterface";
import Seo from "../../components/seo/seo";

export default function Creations() {
  useEffect(() => {
    document.title = 'Maison Aimard - Creations';
    window.scrollTo(0, 0);
    // firebaseApp.analytics().logEvent('track_screen', {
    //   page_location: window.location.href,
    //   page_title: 'Maison Aimard - Creations',
    // });
  }, []);

  const dataOrdered: ICreations = data.sort((a, b) => a.title < b.title ? -1 : 1);

  return (
    <div className={"creations-body"}>
      <Seo title={'Créations et collaborations.'}
           description={'Nous créons, beaucoup même. Nous adorons encore plus broder avec vous et pour vous !'}
           path={'/creations'} contentType={'website'}
           image={'/logo-transparent.png'}/>
      <div className={"row"}>
        <div className={"col-12 text-center"}>
          <h1>Créations</h1>

          <div className={"row"}>
            {dataOrdered.map((creation: ICreation) =>
              (
                <div
                  className={'image-div col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6'}>
                  <CreationBlock title={creation.title} type={creation.type}
                                 image={`/creations/${creation.titleImage}`}
                                 slug={creation.slug}/>
                </div>
              )
            )}
          </div>
        </div>
      </div>

    </div>
  )
}
