import React from "react";

import './CreationBlock.css';
import ImageButton from "../imageButton/ImageButton";

interface ICreationBlock {
  title: string;
  type: string;
  image: string;
  slug: string;
}

export default function CreationBlock(props: ICreationBlock): React.ReactElement {
  const {slug, image, title, type,} = props;

  return (
    <div className={"creationBlock-body"}>
      <div className={'row'}>
        <div className={'col-12'}>

          <div className={'image-div'}>
            <ImageButton width={"100%"} height={"100%"}
                         target={"/creations/" + slug}
                         altText={"Image titre de " + title}
                         image={process.env.PUBLIC_URL + image}/>
          </div>

          <h2>
            <a href={"/creations/" + slug}>{title}</a>
          </h2>

          <h3>{type}</h3>


        </div>

      </div>
    </div>
  )
}
