import React from 'react';

import './button.css'

interface IButton {
  buttonColor?: string;
  leftIcon?: string;
  text: string;
  href: string;
}

export default function Button(props: IButton): React.ReactElement {
  const {href, text} = props;

  return (
    <a href={href} className={'button-cust'}>
      {/*<span className="oi oi-chevron-left" aria-hidden="true" /> {text}*/}
      {`< ${text}`}
    </a>
  );
}
