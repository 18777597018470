import React, {useEffect} from 'react';

import './about.css';

import headerImage
  from './assets/louise-aimard-maison-broderie-art-600x800.jpeg'
import Seo from "../../components/seo/seo";

export default function About(): React.ReactElement {
  useEffect(() => {
    document.title = 'Maison Aimard - À Propos';
    window.scrollTo(0, 0);
    // firebaseApp.analytics().logEvent('track_screen', {
    //   page_location: window.location.href,
    //   page_title: 'Maison Aimard - À Propos',
    // });
  }, []);


  return (
    <div className={"about-body"}>
      <Seo title={'Qui sommes-nous ?'}
           description={'Brodeuse d’art à la main, je suis diplômée d’un CAP et BMA broderie main. Je travaille désormais pour différents clients et ateliers.'}
           path={'/about'} contentType={'website'}
           image={'/logo-transparent.png'}/>
      <div className={"row"}>
        <div className={"col-12"}>
          <h1> À propos </h1>
        </div>
      </div>
      <div className={"row justify-content-center"}>
        <div className={"col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8"}>
          <p className={"first-paragraph"}>Créée en 2019, Maison Aimard est le
            nouveau chapitre d’une
            histoire que je brode
            passionnément. Diplômée d’un CAP et d’un BMA, j’ai travaillé au sein
            d’ateliers réputés qui
            m’ont transmis l’exigence française de cet artisanat.</p>

          <p>En veillant à rester curieuse comme ouverte sur les nouveaux
            procédés de la broderie et en
            participant à sa nécessaire transformation, je souhaite aujourd’hui
            faire perdurer la
            tradition de la broderie d’art. Conjuguer modernité et gestes
            ancestraux est un défi
            essentiel mais profondément enrichissant. Utiliser et dériver les
            techniques, transformer
            les matières et travailler la lumière sont autant de réflexions que
            j’aborde dans mon
            travail : savoir-faire, rigueur et précision en sont les
            maîtres-mots.</p>

          <p>Présenter mes créations est ainsi une véritable mise à nu, une
            proposition intime de mon
            regard sur le monde qui nous entoure.</p>

          <p>Bienvenue !</p>

          <p className={"signature"}>Louise Aimard, fondatrice de Maison
            Aimard</p>
        </div>
        <div className={"col-8 col-sm-6 col-md-4 col-lg-4 col-xl-3"}>
          <img
            src={process.env.PUBLIC_URL + headerImage}
            alt={"Zoom sur des perles"}
            className={"img-fluid rounded"}
          />
        </div>
      </div>
    </div>
  )
}
