import React, {useState} from "react";
import './Menu.css';

import {Link} from 'react-router-dom';

import {slide as Hamburger} from 'react-burger-menu'

interface IMenuProps {
  pageWrapId: string;
  outerContainerId: string;
}

export default function HeaderWithUser(props: IMenuProps): React.ReactElement {
  const {pageWrapId, outerContainerId} = props;

  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  return (
    <Hamburger isOpen={menuOpen}
               onStateChange={(state) => setMenuOpen(state.isOpen)}
               pageWrapId={pageWrapId}
      // customBurgerIcon={false}
               outerContainerId={outerContainerId}>

      <img src={process.env.PUBLIC_URL + "/logo-miniature-blanc.svg"}
           alt={"logo menu"}
           className={"menu-logo img img-responsive"}
      />

      <Link onClick={() => setMenuOpen(false)} className="menu-item"
            to="/">Accueil</Link>
      <div className={"separator-menu"}/>
      <Link onClick={() => setMenuOpen(false)} className="menu-item"
            to="/creations">Créations</Link>
      <div className={"separator-menu"}/>
      <Link onClick={() => setMenuOpen(false)} className="menu-item"
            to="/partners">Partenaires</Link>
      <div className={"separator-menu"}/>
      <a onClick={() => {
        // firebaseApp.analytics().logEvent('track_action', {
        //   action: 'opening etsy',
        //   target: 'https://shop.maison-aimard.com',
        // });
        setMenuOpen(false);
        window.open('https://shop.maison-aimard.com', '_blank')
      }} className="menu-item"
        // href="https://shop.maison-aimard.com"
        // rel={"nofollow noopener noreferrer"} target={"_blank"}
         href="/#"
      >Boutique</a>
      <div className={"separator-menu"}/>
      <Link onClick={() => setMenuOpen(false)} className="menu-item"
            to="/about">À propos</Link>
      <div className={"separator-menu"}/>
      <Link onClick={() => setMenuOpen(false)} className="menu-item"
            to="/contacts">Contact</Link>

    </Hamburger>
  )

}
