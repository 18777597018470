import React, {useEffect} from 'react';
import './Legal.css'
import Seo from "../../components/seo/seo";

export default function Legal(): React.ReactElement {
  useEffect(() => {
    document.title = 'Maison Aimard - Legal';
    window.scrollTo(0, 0);
    // firebaseApp.analytics().logEvent('track_screen', {
    //   page_location: window.location.href,
    //   page_title: 'Maison Aimard - Legal',
    // });
  }, []);

  return (
    <div className={"creations-details-body"}>
      <Seo title={'Les mentions légales'}
           description={'Brodeuse mais soucieuse de votre sécurité et de la protection de vos données.'}
           path={'/legal'} contentType={'website'}
           image={'/logo-transparent.png'}/>
      <div className={"row"}>
        <div className={"col-12 text-center titles"}>
          <h1>Mentions légales</h1>
        </div>
      </div>

      <div className={"separator-30"}/>

      <div className={"row"}>
        <div className={"col-12 text-center titles"}>
          <h2>Identification</h2>

          <p>Maison Aimard est une auto-entreprise, créée par Louise Aimard,
            demeurant au 5 rue Pershing,
            54400 Longwy. Vous pouvez nous contacter au +33 6 49 17 42 17 ou par
            mail à
            contact@maison-aimard.com. Le site est hébérgé par Google
            Firebase.</p>
          <p>Maison Aimard est une entreprise des métiers d’art, immatriculée
            847 871 928 RM 54.</p>
        </div>
      </div>


      <div className={"separator-15"}/>

      <div className={"row"}>
        <div className={"col-12 text-center titles"}>
          <h2>Mentions relative à l'utilisation des cookies</h2>

          <p>Le site que vous visitez utilise des traceurs (cookies). Ainsi, le
            site est susceptible
            d'accéder à des informations déjà stockées dans votre équipement
            terminal de communications
            électroniques et d'y inscrire des informations.</p>
          <p>Le site utilise exclusivement des traceurs dits "strictement
            nécessaires", qui ne nécessitent
            pas votre consentement préalable.</p>
          <p>Nous utilisons ces traceurs pour pour suivre les données
            statistiques de fréquentation du
            site (soit l’utilisation faite du site par les utilisateurs et pour
            améliorer les services
            du site) et pour nous aider à mesurer et étudier l’efficacité de
            notre contenu interactif en
            ligne, de ses caractéristiques. Ces traceurs ne peuvent pas,
            techniquement, être désactivés
            depuis le site. Vous pouvez néanmoins vous opposer à l'utilisation
            de ces traceurs,
            exclusivement en paramétrant votre navigateur. Ce paramétrage dépend
            du navigateur que vous
            utilisez, mais il est en général simple à réaliser : en principe,
            vous pouvez soit activer
            une fonction de navigation privée soit uniquement interdire ou
            restreindre les traceurs
            (cookies). Attention, il se peut que des traceurs aient été
            enregistrés sur votre
            périphérique avant le paramétrage de votre navigateur : dans ce cas,
            effacez votre
            historique de navigation, toujours en utilisant le paramétrage de
            votre navigateur.</p>
        </div>
      </div>


      <div className={"separator-15"}/>

      <div className={"row"}>
        <div className={"col-12 text-center titles"}>
          <h2>Mentions relative à l'utilisation de données personnelles</h2>

          <p>Les données « web analytics » sont collectées de forme anonyme (en
            enregistrant des adresses
            IP anonymes) par Google Analytics, et nous permettent de mesurer
            l'audience de notre site
            web, les consultations et les éventuelles erreurs afin d’améliorer
            constamment l’expérience
            des utilisateurs. Ces données sont utilisées par Maison Aimard,
            responsable du traitement
            des données, et ne seront jamais cédées à un tiers ni utilisées à
            d’autres fins que celles
            détaillées ci-dessus. Les données seront sauvegardées durant une
            durée maximale de 14
            mois.</p>
          <p>Vous avez le droit de consultation, demande de modification ou
            d’effacement sur l’ensemble de
            vos données personnelles. Vous pouvez également retirer votre
            consentement au traitement de
            vos données. Conformément aux lois « Informatique & Liberté » et «
            RGPD », vous pouvez
            exercer vos droits d’accès à vos données, de rectification,
            d’effacement, d’opposition, de
            limitation ou de portabilité en contactant : Louise aimard via
            contact (at) maison-aimard
            (dot) com.</p>
          <p>En cas de différend, vous avez le droit d’introduire une
            réclamation (plainte) auprès de la
            Cnil.</p>
        </div>
      </div>


      <div className={"separator-15"}/>

      <div className={"row"}>
        <div className={"col-12 text-center titles"}>
          <h2>Médiation</h2>

          <p>Conformément aux articles L.616-1 et R.616-1 du code de la
            consommation, notre entreprise a
            mis en place un dispositif de médiation de la consommation. L'entité
            de médiation retenue
            est : CNPM - MEDIATION - CONSOMMATION. En cas de litige, vous pouvez
            déposer votre
            réclamation sur son site : <a
              href={"https://www.cnpm-mediation-consommation.eu"}
              rel={'nofollow'}>https://cnpm-mediation-consommation.eu</a> ou
            par voie postale en écrivant à CNPM - MEDIATION - CONSOMMATION - 27
            Avenue de la Libération
            42400 SAINT-CHAMOND</p>
        </div>
      </div>

    </div>
  )
}
