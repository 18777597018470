import React, {useEffect} from 'react';

import './contact.css';
import headerImage from "./assets/contact-banner.jpg";
import Seo from "../../components/seo/seo";

export default function Contacts(): React.ReactElement {
  useEffect(() => {
    document.title = 'Maison Aimard - Contacts';
    window.scrollTo(0, 0);
    // firebaseApp.analytics().logEvent('track_screen', {
    //   page_location: window.location.href,
    //   page_title: 'Maison Aimard - Contacts',
    // });
  }, []);

  return (
    <div className={"contact-body"}>
      <Seo title={'Contactez-nous !'}
           description={'Nous sommes ravis de donner vie à vos idées et de vous accompagner dans leurs réalisations.'}
           path={'/contacts'} contentType={'website'}
           image={'/logo-transparent.png'}/>
      <div className={"row"}>
        <div className={"col-12"}>
          <h1> Contact </h1>

          <p className={"title"}>Téléphone :</p>
          <p className={"info"}><b>
            <a href={"tel:0033649174217"}>+33 (0) 6 49 17 42 17</a>
          </b></p>

          <p className={"title"}>Adresse email :</p>
          <p className={"info"}><b>
            <a
              href="mailto:contact@maison-aimard.com">contact@maison-aimard.com</a>
          </b></p>

          <p className={"title"}>Possibilités de rendez-vous dans le Grand Est,
            à Paris et au Luxembourg.</p>

          <div className={"separator"}/>

          <div className={"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"}>
            <img
              src={process.env.PUBLIC_URL + headerImage}
              alt={"Zoom sur des perles"}
              className={"img-fluid rounded"}
            />
          </div>

          <div className={"separator"}/>
        </div>
      </div>
    </div>
  )
}
