import React, {useEffect} from 'react';
import './Home.css'
import ImageButton from "../../components/imageButton/ImageButton";

import creations from "./assets/creations.jpg";
import creationsMini from "./assets/creations-mini.jpg";
import partners from "./assets/partenaires.jpg";
import partnersMini from "./assets/partenaires-mini.jpg";
import about from "./assets/about.jpg";
import aboutMini from "./assets/about-mini.jpg";
import shop from "./assets/boutique.jpg";
import shopMini from "./assets/boutique-mini.jpg";
import contact from "./assets/contact.jpg";
import contactMini from "./assets/contact-mini.jpg";
import underliner from "./assets/underliner.png";
import Seo from "../../components/seo/seo";

export default function Home(): React.ReactElement {
  useEffect(() => {
    document.title = 'Maison Aimard - Home';
    window.scrollTo(0, 0);
    // firebaseApp.analytics().logEvent('track_screen', {
    //   page_location: window.location.href,
    //   page_title: 'Maison Aimard - Home',
    // });
  }, []);

  return (
    <div className={"home-body"}>
      <Seo title={'Maison Aimard, broderie d’art à la main'}
           description={'Créations, collaborations et services sur-mesure, de Paris au Luxembourg'}
           path={'/'}
           contentType={'website'} image={'/logo-transparent.png'}/>
      <div className={"row"}>
        <div className={"col-12 text-center"}>
          <h1>Broderie d’art à la main</h1>
          <h2>créations, collaborations et services sur-mesure</h2>
        </div>
      </div>

      <div className={"separator-80"}/>

      <div className={"row"}>
        <div className={"col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"}>
          <div className={"d-none d-sm-none d-md-block"}>
            <ImageButton width={"100%"} height={"100%"} target={"/creations"}
                         altText={"vers creations"}
                         image={creations}
            />
          </div>

          <div className={"d-block d-sm-block d-md-none"}>
            <ImageButton width={"100%"} height={"100%"} target={"/creations"}
                         altText={"vers creations"}
                         image={creationsMini}
            />

            <div className={"separator-30"}/>
          </div>

        </div>
        <div className={"col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"}>
          <div className={"row"}>
            <div className={"col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"}>
              <div className={"d-none d-sm-block"}>
                <ImageButton width={"100%"} height={"100%"} target={"/partners"}
                             altText={"vers partenaires"}
                             image={partners}
                />
              </div>

              <div className={"d-block d-sm-none"}>
                <ImageButton width={"100%"} height={"100%"} target={"/partners"}
                             altText={"vers partenaires"}
                             image={partnersMini}
                />

                <div className={"separator-30"}/>
              </div>

            </div>

            <div className={"col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"}>

              <div className={"d-none d-sm-block"}>
                <ImageButton width={"100%"} height={"100%"} target={"/about"}
                             altText={"vers a propos"}
                             image={about}/>
              </div>

              <div className={"d-block d-sm-none"}>
                <ImageButton width={"100%"} height={"100%"} target={"/about"}
                             altText={"vers a propos"}
                             image={aboutMini}/>

              </div>

            </div>
          </div>

          <div className={"separator-30"}/>

          <div className={"row"}>
            <div className={"col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"}>

              <div className={"d-none d-sm-block"}>
                <ImageButton width={"100%"} height={"100%"} isExternal
                             target={"https://shop.maison-aimard.com"}
                             altText={"vers boutique"}
                             image={shop}/>
              </div>

              <div className={"d-block d-sm-none"}>
                <ImageButton width={"100%"} height={"100%"} isExternal
                             target={"https://shop.maison-aimard.com"}
                             altText={"vers boutique"}
                             image={shopMini}/>

                <div className={"separator-30"}/>
              </div>
            </div>

            <div className={"col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"}>

              <div className={"d-none d-sm-block"}>
                <ImageButton width={"100%"} height={"100%"} target={"/contacts"}
                             altText={"vers contact"}
                             image={contact}/>
              </div>

              <div className={"d-block d-sm-none"}>
                <ImageButton width={"100%"} height={"100%"} target={"/contacts"}
                             altText={"vers contact"}
                             image={contactMini}/>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={"separator-80"}/>

      <div className={"row"}>
        <div className={"col-12 text-center"}>
          <h1>NOTRE SAVOIR-FAIRE</h1>
        </div>
      </div>

      <div className={"separator-30"}/>

      <div className={"row know-how"}>
        <div className={"col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"}>
          <h2>POUR LES PROFESSIONNELS</h2>
          <img
            src={process.env.PUBLIC_URL + underliner}
            alt={"underliner"}
            className={"img-fluid"}
          />
          <p>Création d’échantillons dans le respect des contraintes et de la
            vision du commanditaire.</p>
          <p>Présentation d’échantillons créés à l’atelier et ajustables à vos
            créations.</p>
          <p>Broderie sur patronages adaptée en fonction des différents types de
            tissus.</p>
          <p>Application sur vêtements finis.</p>
          <p>Réédition de pièces brodées.</p>
          <p>Restauration de broderies : arrêts de fils, retouches et poses de
            matières.</p>
          <p>Travail à l’aiguille et au crochet de Lunéville.</p>
        </div>
        <div
          className={"col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 extra-separator"}>
          <h2>POUR LES PARTICULIERS</h2>
          <img
            src={process.env.PUBLIC_URL + underliner}
            alt={"underliner"}
            className={"img-fluid"}
          />
          <p>Application de broderies personnalisées sur vêtements finis.</p>
          <p>Transformation de tenues pour rajeunir un vêtement ou lui donner
            plus d’éclat.</p>
          <p>Restauration de broderies : arrêts de fils, retouches et poses de
            matières.</p>
          <p>Travail à l’aiguille et au crochet de Lunéville.</p>
        </div>
      </div>

      <div className={"separator-80"}/>

      <div className={"row"}>
        <div className={"col-12 text-center"}>
          <h3>Chez Maison Aimard nous avons à coeur de maîtriser les temps de
            travail donnés et les
            budgets impartis, dans le respect du savoir-faire français pour une
            qualité
            haute-couture.</h3>
        </div>
      </div>

      <div className={"separator-30"}/>

      <div className={"row"}>
        <div className={"col-12 text-center"}>
          <p className={"we-work"}>NOS PETITES MAINS TRAVAILLENT POUR VOUS MAIS
            ÉGALEMENT POUR :</p>
          <p className={"we-work-for"}>Chanel, Saint-Laurent, Lesage, Celine,
            Louis Vuitton, Stéphane
            Rolland, Armine Ohanyan, Célestina Agostino…</p>
        </div>
      </div>

    </div>
  )
}
