// code adapted from https://themeteorchef.com/tutorials/reusable-seo-with-react-helmet

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

const absoluteUrl = (path: string) => `https://maison-aimard.com${path}`;
const seoImageURL = (file: string) => `https://maison-aimard.com${file}`;

export interface IGetMetaTags {
  title: string;
  description: string;
  url: string;
  contentType: string;
  published?: string;
  updated?: string;
  category?: string;
  tags?: [string];
  // twitter: string;
  image?: string;
}

const getMetaTags = (data: IGetMetaTags) => {
  const metaTags = [
    {itemprop: 'name', content: data.tags},
    {itemprop: 'description', content: data.description},
    {name: 'description', content: data.description},
    // {name: 'twitter:site', content: '@stereobooster'}, // '@stereobooster'
    // {name: 'twitter:title', content: `${data.title}`}, // | yagd-9d9f8.firebaseapp.com
    // {name: 'twitter:description', content: data.description},
    // {name: 'twitter:creator', content: data.twitter}, //  || '@stereobooster'
    {name: 'og:title', content: `${data.title}`}, //  | yagd-9d9f8.firebaseapp.com
    {name: 'og:type', content: data.contentType},
    {name: 'og:url', content: data.url},
    {name: 'og:description', content: data.description},
    {name: 'og:site_name', content: 'maison-aimard.com'},
    {name: 'og:locale', content: 'fr_FR'},
    // { name: 'fb:app_id', content: '<FB App ID>' },
  ];

  if (data.published) metaTags.push({
    name: 'article:published_time',
    content: data.published
  });
  if (data.updated) metaTags.push({
    name: 'article:modified_time',
    content: data.updated
  });
  if (data.category) metaTags.push({
    name: 'article:section',
    content: data.category
  });
  if (data.tags) metaTags.push({
    name: 'article:tag',
    content: data.tags.toString()
  });
  if (data.image) {
    metaTags.push({itemprop: 'image', content: seoImageURL(data.image)});
    metaTags.push({
      name: 'twitter:image:src',
      content: seoImageURL(data.image)
    });
    metaTags.push({name: 'og:image', content: seoImageURL(data.image)});
    metaTags.push({name: 'twitter:card', content: 'summary_large_image'});
  } else {
    metaTags.push({name: 'twitter:card', content: 'summary'});
  }

  return metaTags;
};

const getHtmlAttributes = (schema?: string) => {
  let result: any = {
    lang: 'fr',
  };
  if (schema) {
    result = {
      ...result,
      itemscope: undefined,
      itemtype: `https://schema.org/${schema}`,
    }
  }
  return result;
}

getHtmlAttributes.propTypes = {
  schema: PropTypes.string,
};

export interface ISeoData {
  schema?: string;
  title: string;
  description: string;
  path: string;
  contentType: string;
  published?: string;
  updated?: string;
  category?: string;
  tags?: [string];
  // twitter: string;
  image?: string;
}

const Seo = (data: ISeoData) => (
  <Helmet
    htmlAttributes={getHtmlAttributes(
      data.schema,
    )}
    title={data.title}
    link={[
      {rel: 'canonical', href: absoluteUrl(data.path)},
    ]}
    meta={getMetaTags({
      title: data.title,
      description: data.description,
      contentType: data.contentType,
      url: absoluteUrl(data.path),
      published: data.published,
      updated: data.updated,
      category: data.category,
      tags: data.tags,
      // twitter: data.twitter,
      image: data.image,
    }) as any}
  />
);

// Seo.propTypes = {
//     schema: PropTypes.string,
//     title: PropTypes.string,
//     description: PropTypes.string,
//     path: PropTypes.string,
//     contentType: PropTypes.string,
//     published: PropTypes.string,
//     updated: PropTypes.string,
//     category: PropTypes.string,
//     tags: PropTypes.array,
//     twitter: PropTypes.string,
//     image: PropTypes.string,
// };

export default Seo;
