import React from "react";

import "./Partner.css"

interface IPartner {
  image: string;
  imageAlt: string;
  title: string;
  description: string;
  websiteURL: string;
}

export function Partner(props: IPartner): React.ReactElement {
  return (
    <div className={"row align-items-center partner-body"}>
      <div className={"col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 wrapper"}>
        <a href={props.websiteURL} rel={"nofollow noopener noreferrer"}
           target={"_blank"}>
          <img
            className={"img-fluid img-thumbnail rounded-circle"}
            src={process.env.PUBLIC_URL + '/partners/' + props.image}
            alt={props.imageAlt}
          />
        </a>
      </div>

      <div className={"col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10"}>
        <h2>{props.title}</h2>
        <p>{props.description}</p>
      </div>
    </div>
  );
}
