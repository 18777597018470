import React from "react";

import "./ImageButton.css";
import {Link} from "react-router-dom";

interface IImageButton {
  width: string;
  height: string;
  target: string;
  image: string;
  altText: string;
  isExternal?: boolean;
}

export default function ImageButton(props: IImageButton): React.ReactElement {
  if (props.isExternal) {
    return (
      <div className={"img-hover-zoom"}>
        <a
          onClick={() => {
            // firebaseApp.analytics().logEvent('track_action', {
            //   action: 'opening etsy',
            //   target: props.target,
            // });
            window.open(props.target, '_blank')
          }}
          href='/#'
          // href={props.target}
          // rel={"nofollow noopener noreferrer"}
          // target={"_blank"}
        >
          <img alt={props.altText}
               src={props.image}
               width={props.width}
               height={props.height}
          />
        </a>
      </div>
    );
  } else {
    return (
      <div className={"img-hover-zoom"}>
        <Link to={props.target}>
          <img alt={props.altText}
               src={props.image}
               width={props.width}
               height={props.height}
          />
        </Link>
      </div>
    );
  }
}
