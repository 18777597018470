import React, {Component} from 'react';
import './App.css';
import {Route, Routes, Link} from 'react-router-dom';

import Menu from './components/menu/Menu'
import Footer from './components/footer/Footer'

import Home from './screens/home/Home'
import Legal from './screens/legal/Legal'
import About from './screens/about/About'
import Contacts from './screens/contact/Contacts'
import Creations from './screens/creations/Creations'
import PartnersScreen from './screens/partners/PartnersScreen'
import CreationsDetails from './screens/creations/CreationsDetails'

import CookieConsent from 'react-cookie-consent';

// export const firebaseApp = firebase.initializeApp({
//   apiKey: "AIzaSyAZcdrM9FpUxYCLXWwGKr9o6xFI6TUCfMw",
//   authDomain: "maison-aimard-website.firebaseapp.com",
//   projectId: "maison-aimard-website",
//   storageBucket: "maison-aimard-website.appspot.com",
//   messagingSenderId: "32146376360",
//   appId: "1:32146376360:web:d78dcc36ceb4309495e49d",
//   measurementId: "G-KBZRYXQ398",
// });

class App extends Component {
  componentDidMount() {
  }

  render() {
    return (
      <div id={"outer-container"} style={{height: '100%'}}>

        <Menu pageWrapId={"page-wrap"} outerContainerId={"outer-container"}/>

        <main id={"page-wrap"} style={{height: '100%'}}>
          <div className={"container"}>

            <div className={"row justify-content-center"}>
              <div className={"col-12 text-center"} id={"logo-div"}>
                <Link to="/">
                  <img id={"logo"}
                       src={process.env.PUBLIC_URL + "/logo-transparent.png"}
                       alt={"Logo maison Aimard"}/>
                </Link>
              </div>
            </div>

            <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="/creations" element={<Creations/>}/>
              <Route path="/creations/:slug" element={<CreationsDetails/>}/>
              <Route path="/partners" element={<PartnersScreen/>}/>
              <Route path="/about" element={<About/>}/>
              <Route path="/contacts" element={<Contacts/>}/>
              <Route path="/legal" element={<Legal/>}/>
              <Route element={<Home/>}/>
            </Routes>

          </div>

          <Footer/>
        </main>
        <CookieConsent
          location="bottom"
          buttonText="OK"
          style={{background: "#032d2c", color: "#eaeaea"}}
          buttonStyle={{background: "#015c2f", color: "#eaeaea"}}
        >
          En continuant votre navigation sur ce site, vous acceptez
          l'utilisation des cookies afin
          d'assurer le bon déroulement de votre visite et de réaliser des
          statistiques
          d'audience.&nbsp;
          <Link to="/legal">
            <span style={{fontSize: "10px"}}>En savoir plus</span>
          </Link>
        </CookieConsent>
      </div>
    );
  }
}

export default App;
