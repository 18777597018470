import React, {useEffect} from 'react';

import './PartnersScreen.css';
import {Partner} from "../../components/Partner/Partner";

import data from './PartnersData';
import Seo from "../../components/seo/seo";

interface IPartnersModel {
  title: string;
  description: string;
  image: string;
  imageAlt: string;
  website: string;
}

export interface IPartnersModels extends Array<IPartnersModel> {
}

export default function PartnersScreen(): React.ReactElement {
  useEffect(() => {
    document.title = 'Maison Aimard - Partenaires';
    window.scrollTo(0, 0);
    // firebaseApp.analytics().logEvent('track_screen', {
    //   page_location: window.location.href,
    //   page_title: 'Maison Aimard - Partenaires',
    // });
  }, []);


  return (
    <div className={"partners-body"}>
      <Seo title={'Nos partenaires, fiers collaborateurs.'}
           description={'Maison Aimard n’est rien sans ses partenaires, qu’ils soient brodeurs, photographes ou magiciens !'}
           path={'/partners'} contentType={'website'}
           image={'/logo-transparent.png'}/>
      <div className={"row"}>
        <div className={"col-12"}>
          <h1> Partenaires </h1>

          {data.map((partner) => {
            return (
              <div>
                <Partner
                  image={partner.image}
                  imageAlt={partner.imageAlt}
                  title={partner.title}
                  description={partner.description}
                  websiteURL={partner.website}
                />

                <div className={"separator"}/>
              </div>
            )
          })}

        </div>
      </div>
    </div>
  )
}
